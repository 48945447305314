import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import parkdaleOrganizeJpgSrc from '../assets/images/parkdaleOrganize.jpg';
import torontoTenantUnionProtestSrc from '../assets/images/torontoTenantUnionProtest.webp';
import vancouverCityHallProtestSrc from '../assets/images/vancouverCityHallProtest.webp';
import latuProtestSrc from '../assets/images/latuProtest.jpg';

const TenantUnions = () => {
  const navigate = useNavigate();

  return (
    <Container
      component={'article'}
      sx={{ width: { xs: '100%', md: '80%' }, padding: { xs: '5px', sm: '10px' } }}
    >
      <Box>
        <Typography variant="h1">The Power of Tenant Unions: A Path to Housing Justice</Typography>
        <Typography paddingLeft={{ md: '50px' }} variant="subtitle1">
          <a onClick={() => navigate('/resources')}>
            Check out our collection of localized resources including links to tenant unions
          </a>
        </Typography>
        <Typography marginTop={'5px'} sx={{ textIndent: { xs: '10px', md: '20px' } }}>
          Housing is a fundamental human right, and ensuring that everyone has access to safe,
          secure, clean, affordable, and accessible housing is a moral imperative. Unfortunately, in
          many parts of the world, the rights of tenants are often overshadowed by the interests of
          landlords and property owners. This is where tenant unions come into play.
        </Typography>
        <Typography marginTop={'10px'} variant="h2">
          Housing as a Human Right
        </Typography>
        <Box>
          <Box
            sx={{
              display: { xs: 'none', md: 'inherit' },
              float: 'right',
              maxWidth: '40%',
            }}
          >
            <Box borderRadius={'4px'} display={'flex'} overflow={'hidden'}>
              <img width={'100%'} src={torontoTenantUnionProtestSrc} />
            </Box>
            <Typography color={'#D90368'} variant="caption">
              Tenants and advocates march from Queen's Park to the Landlord and Tenant Board earlier
              this year to protest evictions during the COVID-19 pandemic
            </Typography>
          </Box>
          <Typography marginTop={'5px'} sx={{ textIndent: { xs: '10px', md: '20px' } }}>
            Before delving into the role of tenant unions, it's crucial to emphasize that housing is
            indeed a human right. The United Nations Universal Declaration of Human Rights, adopted
            in 1948, recognizes "the right to a standard of living adequate for the health and
            well-being of himself and his family, including... housing." This declaration
            underscores the principle that everyone deserves to live with dignity in a safe, secure,
            clean, affordable, and accessible home.
          </Typography>

          <Box
            sx={{
              display: { xs: 'inherit', md: 'none' },
              float: 'right',
              maxWidth: { xs: '100%', md: '40%' },
            }}
          >
            <Box borderRadius={'4px'} display={'flex'} overflow={'hidden'}>
              <img width={'100%'} src={torontoTenantUnionProtestSrc} />
            </Box>
            <Typography color={'#D90368'} variant="caption">
              Tenants and advocates march from Queen's Park to the Landlord and Tenant Board earlier
              this year to protest evictions during the COVID-19 pandemic
            </Typography>
          </Box>

          <Typography marginTop={'5px'} sx={{ textIndent: { xs: '10px', md: '20px' } }}>
            Yet, the reality is far from this ideal in many places around the world. The cost of
            housing has skyrocketed in numerous urban areas, making it unattainable for low and
            middle-income individuals and families. Landlords sometimes exploit their power,
            neglecting maintenance and repairs, raising rents indiscriminately, and evicting tenants
            without just cause. In such an environment, tenant unions emerge as a potent tool to
            protect the rights of renters and promote housing justice.
          </Typography>
        </Box>
        <Typography marginTop={'10px'} variant="h2">
          The Benefits of Tenant Unions
        </Typography>
        <Typography>
          Tenant unions offer a wide range of benefits to renters and their communities:
        </Typography>
        <ul>
          <Typography color={'#D90368'} variant="h3">
            <li>Collective Bargaining Power</li>
          </Typography>
          <Typography>
            Individually, tenants may feel powerless when dealing with landlords or property
            management companies. Tenant unions provide a platform for renters to join forces,
            pooling their collective bargaining power. This strength can be used to negotiate fair
            rental agreements, demand necessary repairs, and resist unjust evictions.
          </Typography>

          <Typography color={'#D90368'} variant="h3">
            <li>Legal Assistance and Education </li>
          </Typography>
          <Typography>
            Tenant unions often have access to legal resources and expertise. They can educate
            members about their rights and responsibilities as renters, offering guidance on
            navigating lease agreements and local housing laws. In cases of landlord misconduct,
            tenant unions can provide legal support, helping tenants pursue justice.
          </Typography>

          <Typography color={'#D90368'} variant="h3">
            <li>Advocacy for Policy Change </li>
          </Typography>
          <Typography>
            Tenant unions are not only concerned with individual issues but also work to effect
            broader change. They advocate for tenant-friendly legislation, rent control, and
            affordable housing policies at the local, state, and national levels. Through lobbying
            and grassroots organizing, they seek to create a fairer housing landscape for all.
          </Typography>

          <Typography color={'#D90368'} variant="h3">
            <li> Community Building </li>
          </Typography>
          <Typography>
            Tenant unions foster a sense of community and solidarity among renters. They provide a
            platform for tenants to share their experiences and support one another. This sense of
            belonging can be empowering and help individuals overcome the isolation that often comes
            with being a tenant.
          </Typography>

          <Typography color={'#D90368'} variant="h3">
            <li>Accountability for Landlords </li>
          </Typography>
          <Typography>
            By holding landlords accountable for their actions, tenant unions can help ensure that
            rental properties are maintained in good condition, repairs are made promptly, and rent
            increases are justified. Landlords are less likely to engage in abusive practices when
            they know tenants have a united front.
          </Typography>
        </ul>
        <Box display={'flex'} flexDirection={'column'}>
          <Box borderRadius={'4px'} display={'flex'} overflow={'hidden'}>
            <img width={'100%'} src={vancouverCityHallProtestSrc} />
          </Box>
          <Typography textAlign={'center'} color={'#D90368'} variant="caption">
            Vancouver Tenants Union hosts a rally outside city hall calling on councilors to stop
            the Broadway Plan. If passed, they fear the plan will displace thousands of renters who
            call the area home.
          </Typography>
        </Box>
        <Typography marginTop={'10px'} variant="h2">
          When Tenants Should Form a Union
        </Typography>
        <Typography>
          Forming a tenant union can be a strategic choice in various situations:
        </Typography>
        <ul>
          <Typography color={'#D90368'} variant="h3">
            <li> Neglect of Repairs and Maintenance</li>
          </Typography>
          <Typography>
            Persistent neglect of necessary repairs and maintenance by landlords can make living
            conditions unsafe and unhealthy. If complaints to the landlord or property management
            fall on deaf ears, forming a tenant union can exert pressure for much-needed
            improvements.
          </Typography>

          <Typography color={'#D90368'} variant="h3">
            <li> Unjust Evictions</li>
          </Typography>
          <Typography>
            In many jurisdictions, tenants can be evicted for reasons that may seem arbitrary or
            unfair. Tenant unions can provide support and resources to tenants facing unjust
            evictions, helping them challenge such actions in court or through community
            mobilization.
          </Typography>

          <Typography color={'#D90368'} variant="h3">
            <li> Advocacy for Policy Change</li>
          </Typography>
          <Typography>
            When there is a need for policy changes at the local or state level to protect renters'
            rights, tenant unions can play a pivotal role in advocacy efforts. They can mobilize
            members to campaign for tenant-friendly legislation, rent control, or affordable housing
            initiatives.
          </Typography>

          <Typography color={'#D90368'} variant="h3">
            <li>Rent Increases Beyond Inflation</li>
          </Typography>
          <Typography>
            When landlords consistently raise rents well above the rate of inflation, it becomes
            financially burdensome for tenants. This can force individuals and families to make
            difficult choices between paying rent and meeting other basic needs. A tenant union can
            collectively negotiate for fair rent increases that reflect the economic realities of
            the area.
          </Typography>
        </ul>
        <Typography marginTop={'10px'} variant="h2">
          Tenant Union Success Stories
        </Typography>
        <Typography marginTop={'5px'} sx={{ textIndent: { xs: '10px', md: '20px' } }}>
          To illustrate the impact and effectiveness of tenant unions, let's explore two real-world
          success stories:
        </Typography>
        <Typography variant="h3">
          🇺🇸{' '}
          <a href="https://latenantsunion.org" target="_blank" rel="noopener">
            The Los Angeles Tenants Union (LATU){' '}
            <FontAwesomeIcon
              style={{ verticalAlign: 'middle' }}
              fontSize={'0.75rem'}
              icon={faUpRightFromSquare}
            />
          </a>
        </Typography>
        <Box>
          <Box
            borderRadius={'4px'}
            overflow={'hidden'}
            display={{ xs: 'none', md: 'flex' }}
            maxWidth={'40%'}
            sx={{ float: 'right' }}
          >
            <img width={'100%'} src={latuProtestSrc} />
          </Box>
          <Typography marginTop={'5px'} sx={{ textIndent: { xs: '10px', md: '20px' } }}>
            Los Angeles, California, has faced one of the most severe housing crises in the United
            States. Skyrocketing rents and a lack of affordable housing options have left many
            tenants vulnerable to exploitation and eviction. In this challenging environment, the
            Los Angeles Tenants Union (LATU) emerged as a formidable force for tenant rights.
          </Typography>
          <Typography marginTop={'5px'} sx={{ textIndent: { xs: '10px', md: '20px' } }}>
            LATU has consistently advocated for tenant protections, leading to local ordinances that
            limit unjust evictions and provide tenants with more security. Their work has included
            pushing for "just cause" eviction policies and renter anti-harassment measures in
            various neighborhoods across Los Angeles.
          </Typography>
          <Box borderRadius={'4px'} overflow={'hidden'} display={{ xs: 'flex', md: 'none' }}>
            <img width={'100%'} src={latuProtestSrc} />
          </Box>
        </Box>

        <Typography variant="h3">
          🇨🇦{' '}
          <a
            href="http://parkdaleorganize.ca/wp-content/uploads/2017/05/This-is-Parkdale-Issue-6.pdf"
            target="_blank"
            rel="noopener"
          >
            Parkdale Organize{' '}
            <FontAwesomeIcon
              style={{ verticalAlign: 'middle' }}
              fontSize={'0.75rem'}
              icon={faUpRightFromSquare}
            />
          </a>
        </Typography>
        <Box>
          <Box
            borderRadius={'4px'}
            overflow={'hidden'}
            display={{ xs: 'none', md: 'flex' }}
            maxWidth={'40%'}
            sx={{ float: 'right' }}
          >
            <img width={'100%'} src={parkdaleOrganizeJpgSrc} />
          </Box>
          <Typography marginTop={'5px'} sx={{ textIndent: { xs: '10px', md: '20px' } }}>
            Toronto, Canada's largest city, has been facing a housing affordability crisis in recent
            years. Rent increases have outpaced income growth, leading to widespread tenant
            vulnerability and displacement. In response to these challenges, the Parkdale
            neighborhood in Toronto saw the emergence of a tenant union known as "Parkdale
            Organize."
          </Typography>
          <Typography marginTop={'5px'} sx={{ textIndent: { xs: '10px', md: '20px' } }}>
            Parkdale Organize gained prominence through their rent strikes and "RentSafeTO"
            campaign. The tenants in Parkdale faced aggressive rent increases from a property
            management company, MetCap Living. In response, Parkdale Organize organized rent strikes
            and garnered significant media attention.
          </Typography>
          <Box borderRadius={'4px'} overflow={'hidden'} display={{ xs: 'flex', md: 'none' }}>
            <img width={'100%'} src={parkdaleOrganizeJpgSrc} />
          </Box>
        </Box>

        <Typography marginTop={'10px'} variant="h2">
          Conclusion
        </Typography>
        <Typography marginTop={'5px'} sx={{ textIndent: { xs: '10px', md: '20px' } }}>
          Tenant unions are a vital force for housing justice, ensuring that the basic human right
          to safe, secure, clean, affordable, and accessible housing is upheld. By offering
          collective bargaining power, legal assistance, advocacy, and community support, tenant
          unions empower renters to stand up to unjust landlords and advocate for systemic change.
        </Typography>
        <Typography marginTop={'5px'} sx={{ textIndent: { xs: '10px', md: '20px' } }}>
          In a world where housing is increasingly unaffordable and landlords often hold
          disproportionate power, tenant unions serve as a beacon of hope for tenants. The success
          stories of tenant movements in places like Chicago and Berlin illustrate the tangible
          impact that organized tenant activism can have on improving housing conditions and
          securing the rights of renters. As we move forward, it is essential to recognize the
          importance of tenant unions in the fight for housing justice and ensure that everyone can
          live with the dignity they deserve.
        </Typography>
      </Box>
    </Container>
  );
};

export default TenantUnions;
